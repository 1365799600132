<template>
  <CCard>
    <CCardHeader>
      {{!this.isEdit ? $t('Добавление события') : $t('Редактирование события') }}
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="save(model)">
        <CInput
            :description="$t('Его будут видеть подписчики')"
            :label="$t('Название')"
            horizontal
            autocomplete="summary"
            v-model.lazy="model.summary"
            required
        />
        <CTextarea
            :description="$t('Его будут видеть подписчики')"
            :label="$t('Описание')"
            placeholder=""
            horizontal
            rows="9"
            v-model.lazy="model.description"
        />
        <CRow form class="form-group" v-if="calendar.categories_enabled && categories.length > 1">
          <CCol sm="3">
          </CCol>
          <CInputRadioGroup
              class="col-sm-9"
              :options="categoryTypeOptions"
              :custom="false"
              :checked.sync="model.categoryType"
              :inline="true"
          />
        </CRow>
        <CInput
            v-if="calendar.categories_enabled && model.categoryType === categoryTypeOptions[1]"
            :description="$t('Его будут видеть подписчики')"
              :label="$t('Новая категория')"
            horizontal
            autocomplete="category"
            v-model.lazy="model.category"
        />
        <CSelect v-if="categories.length > 1 && calendar.categories_enabled && model.categoryType === categoryTypeOptions[0]"
            :label="$t('Категория')"
            :description="$t('Её будут видеть подписчики')"
            horizontal
            :options="categories"
            :value.sync="model.category_id"
        />
        <hr>
        <CInput
            :description="$t('Его будут видеть подписчики')"
            :label="$t('Место события')"
            horizontal
            autocomplete="location"
            v-model.lazy="model.location"
        />
        <CInput
            :description="$t('Его будут видеть подписчики')"
            :label="$t('Организатор события')"
            horizontal
            autocomplete="organizer"
            v-model.lazy="model.organizer"
        />
        <CInput
            :description="$t('Его будут видеть подписчики')"
            label="Url события"
            horizontal
            autocomplete="url"
            v-model.lazy="model.url"
            type="url"
        />
        <hr>
        <CSelect
            :label="$t('Часовой пояс события')"
            :description="$t('Ниже вы указываете время в этом часовом поясе')"
            horizontal
            :options="timezones"
            :value.sync="model.timezone"
        />
        <CRow form class="form-group">
          <CCol sm="3">
            {{$t('Время начала')}}
          </CCol>
          <CInputRadioGroup
              class="col-sm-9"
              :options="startTypeOptions"
              :custom="false"
              :checked.sync="model.startType"
              :inline="true"
          />
        </CRow>
        <div v-if="model.startType === startTypeOptions[0]">
          <CInput
              label=" "
              type="datetime-local"
              horizontal
              v-model.lazy="model.startDate"
          />
        </div>
        <div v-if="model.startType === startTypeOptions[1]">
          <CInput
              :append="$t('дней после подписки')"
              :label="$t('Начало события через ...')"
              horizontal
              v-model.lazy="model.startDays"
              type="number"
          />
          <CInput
              :append="$t('часов после подписки')"
              label=" "
              horizontal
              v-model.lazy="model.startHours"
              type="number"
          />
          <CInput
              :append="$t('минут после подписки')"
              label=" "
              horizontal
              v-model.lazy="model.startMinutes"
              type="number"
          />
        </div>
        <div v-if="model.startType === startTypeOptions[2]">
          <CInput
              :append="$t('дней после подписки')"
              :label="$t('Начало события через ...')"
              :description="$t('0, если событие в день подписки')"
              horizontal
              v-model.lazy="model.startDays2"
              type="number"
          />
          <CInput
              :label="$t('В ...')"
              :append="$t('часов этого дня')"
              type="time"
              horizontal
              v-model.lazy="model.startTime2"
          />
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{$t('Перенести на следующий день, если время в день подписки прошло')}}
            </CCol>
            <CCol sm="9">
              <CSwitch
                  class="mr-1"
                  color="info"
                  v-model.lazy="model.changeDayIfTimePassed"
                  :checked.sync="model.changeDayIfTimePassed"
              />
            </CCol>
          </CRow>
        </div>
        <hr>
        <CRow form class="form-group" hidden>
          <CCol tag="label" sm="3" class="col-form-label">
            {{$t('Есть время окончания')}}
          </CCol>
          <CCol sm="9">
            <CSwitch
                class="mr-1"
                color="info"
                v-model.lazy="model.endEnabled"
                :checked.sync="model.endEnabled"
            />
          </CCol>
        </CRow>
        <CRow form class="form-group" v-if="model.endEnabled">
          <CCol sm="3">
            {{$t('Время окончания')}}
          </CCol>
          <CInputRadioGroup
              class="col-sm-9"
              :options="endTypeOptions"
              :custom="false"
              :checked.sync="model.endType"
              :inline="true"
          />
        </CRow>
        <CInput
            v-if="model.endEnabled && model.endType === endTypeOptions[0]"
            label=" "
            type="datetime-local"
            horizontal
            v-model.lazy="model.endDate"
        />
        <CInput
            v-if="model.endEnabled && model.endType === endTypeOptions[1]"
            :append="$t('дней после начала')"
            :label="this.$t('Окончание через ...')"
            horizontal
            v-model.lazy="model.endDays"
            type="number"
        />
        <CInput
            v-if="model.endEnabled && model.endType === endTypeOptions[1]"
            :append="$t('часов после начала')"
            label=" "
            horizontal
            v-model.lazy="model.endHours"
            type="number"
        />
        <CInput
            v-if="model.endEnabled && model.endType === endTypeOptions[1]"
            :append="$t('минут после начала')"
            label=" "
            horizontal
            v-model.lazy="model.endMinutes"
            type="number"
        />
        <hr>
        <CRow form class="form-group">
          <CCol tag="label" sm="3" class="col-form-label">
            {{$t('Включить первое напоминание')}}
          </CCol>
          <CCol sm="9">
            <CSwitch
                class="mr-1"
                color="info"
                v-model.lazy="model.alarm1Enabled"
                :checked.sync="model.alarm1Enabled"
            />
          </CCol>
        </CRow>
        <CRow form class="form-group" v-if="model.alarm1Enabled" hidden>
          <CCol sm="3">
            {{ $t('Время первого напоминания') }}
          </CCol>
          <CInputRadioGroup
              class="col-sm-9"
              :options="alarmTimeTypeOptions"
              :custom="false"
              :checked.sync="model.alarm1TimeType"
              :inline="true"
          />
        </CRow>
        <CInput
            v-if="model.alarm1Enabled && model.alarm1TimeType === alarmTimeTypeOptions[0]"
            type="datetime-local"
            horizontal
            v-model.lazy="model.alarm1Date"
        />
        <CInput
            v-if="model.alarm1Enabled && model.alarm1TimeType === alarmTimeTypeOptions[1]"
            :append="$t('дней до события')"
            :label="this.$t('Напоминание за ...')"
            horizontal
            v-model.lazy="model.alarm1Days"
            type="number"
        />
        <CInput
            v-if="model.alarm1Enabled && model.alarm1TimeType === alarmTimeTypeOptions[1]"
            :append="this.$t('часов до события')"
            label=" "
            horizontal
            v-model.lazy="model.alarm1Hours"
            type="number"
        />
        <CInput
            v-if="model.alarm1Enabled && model.alarm1TimeType === alarmTimeTypeOptions[1]"
            :append="this.$t('минут до события')"
            label=" "
            horizontal
            v-model.lazy="model.alarm1Minutes"
            type="number"
        />
        <CInput
            v-if="model.alarm1Enabled && model.alarm1TimeType === alarmTimeTypeOptions[1]"
            label=" "
            horizontal
            v-model.lazy="model.alarm1Seconds"
            type="number"
            hidden
        />
        <hr>
        <CRow form class="form-group">
          <CCol tag="label" sm="3" class="col-form-label">
            {{ $t('Включить второе напоминание') }}
          </CCol>
          <CCol sm="9">
            <CSwitch
                class="mr-1"
                color="info"
                v-model.lazy="model.alarm2Enabled"
                :checked.sync="model.alarm2Enabled"
            />
          </CCol>
        </CRow>
        <CRow form class="form-group" v-if="model.alarm2Enabled" hidden>
          <CCol sm="3">
            {{ $t('Время второго напоминания') }}
          </CCol>
          <CInputRadioGroup
              class="col-sm-9"
              :options="alarmTimeTypeOptions"
              :custom="false"
              :checked.sync="model.alarm2TimeType"
              :inline="true"
          />
        </CRow>
        <CInput
            v-if="model.alarm2Enabled && model.alarm2TimeType === alarmTimeTypeOptions[0]"
            type="datetime-local"
            horizontal
            v-model.lazy="model.alarm2Date"
        />
        <CInput
            v-if="model.alarm2Enabled && model.alarm2TimeType === alarmTimeTypeOptions[1]"
            :append="this.$t('дней до события')"
            :label="this.$t('Напоминание за ...')"
            horizontal
            v-model.lazy="model.alarm2Days"
            type="number"
        />
        <CInput
            v-if="model.alarm2Enabled && model.alarm2TimeType === alarmTimeTypeOptions[1]"
            :append="this.$t('часов до события')"
            label=" "
            horizontal
            v-model.lazy="model.alarm2Hours"
            type="number"
        />
        <CInput
            v-if="model.alarm2Enabled && model.alarm2TimeType === alarmTimeTypeOptions[1]"
            :append="this.$t('минут до события')"
            label=" "
            horizontal
            v-model.lazy="model.alarm2Minutes"
            type="number"
        />
        <CInput
            v-if="model.alarm2Enabled && model.alarm2TimeType === alarmTimeTypeOptions[1]"
            label=" "
            horizontal
            v-model.lazy="model.alarm2Seconds"
            type="number"
            hidden
        />
        <hr>
        <CInput
            hidden
            horizontal
            v-model.lazy="model.id"
        />
        <router-link :to="'/calendar/' + this.$route.params.cid"><CButton type="submit" size="sm" color="dark">{{ this.$t('Назад') }}</CButton></router-link>
        <CButton type="submit" size="sm" color="success">{{!this.isEdit ? this.$t('Добавить') : this.$t('Обновить') }}</CButton>
        <CButton size="sm" color="danger" v-if="this.isEdit" @click="this.delete">{{this.$t('Удалить событие')}}</CButton>
        <router-link :to="'/calendar/add-event/' + this.$route.params.cid + '/' + this.$route.params.id" v-if="this.isEdit"><CButton type="submit" size="sm" color="info">{{ this.$t('Копировать событие') }}</CButton></router-link>
      </CForm>
      <notifications group="calendar" />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from '@/modules/axios'

let alarmTimeTypes = [
  'Определенное',
  'Относительно начала события',
]

let startTypes = [
  'Определенное',
  'Относительно момента подписки',
  'В определенное время относительного дня',
]

let endTypes = [
  'Определенное',
  'Относительно начала события',
]

let categoryTypes = [
  'Выбрать из имеющихся',
  'Новая категория',
]

export default {
  name: 'CalendarAddEvent',
  components: {
  },
  data () {
    startTypes.forEach((v, k) => {
      startTypes[k] = this.$t(v)
    })
    endTypes.forEach((v, k) => {
      endTypes[k] = this.$t(v)
    })
    alarmTimeTypes.forEach((v, k) => {
      alarmTimeTypes[k] = this.$t(v)
    })
    categoryTypes.forEach((v, k) => {
      categoryTypes[k] = this.$t(v)
    })
    return {
      categories: [],
      alarmTimeTypeOptions: alarmTimeTypes,
      startTypeOptions: startTypes,
      categoryTypeOptions: categoryTypes,
      endTypeOptions: endTypes,
      calendar: {
        name: '',
        desc: '',
        categories_enabled: false,
      },
      model: {
        id: 0,
        category_id: 0,
        categoryType: categoryTypes[1],
        category: '',
        summary: '',
        description: '',
        startType: startTypes[0],
        startDate: '',
        startDays: 0,
        startHours: 1,
        startMinutes: 0,
        startSeconds: 0,
        startDays2: 0,
        startTime2: '',
        changeDayIfTimePassed: true,
        endEnabled: true,
        endType: endTypes[0],
        endDate: '',
        endDays: 0,
        endHours: 1,
        endMinutes: 0,
        endSeconds: 0,
        location: '',
        organizer: '',
        url: '',
        alarm1Enabled: false,
        alarm1TimeType: alarmTimeTypes[1],
        alarm1Date: '',
        alarm1Days: 0,
        alarm1Hours: 1,
        alarm1Minutes: 0,
        alarm1Seconds: 0,
        alarm2Enabled: false,
        alarm2TimeType: alarmTimeTypes[1],
        alarm2Date: '',
        alarm2Days: 0,
        alarm2Hours: 0,
        alarm2Minutes: 0,
        alarm2Seconds: 0,
        calendar_id: 0,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      timezones: [
        {"label":"(GMT-12:00) International Date Line West","value":"Etc/GMT+12"},
        {"label":"(GMT-11:00) Midway Island, Samoa","value":"Pacific/Midway"},
        {"label":"(GMT-10:00) Hawaii","value":"Pacific/Honolulu"},
        {"label":"(GMT-09:00) Alaska","value":"US/Alaska"},
        {"label":"(GMT-08:00) Pacific Time (US & Canada)","value":"America/Los_Angeles"},
        {"label":"(GMT-08:00) Tijuana, Baja California","value":"America/Tijuana"},
        {"label":"(GMT-07:00) Arizona","value":"US/Arizona"},
        {"label":"(GMT-07:00) Chihuahua, La Paz, Mazatlan","value":"America/Chihuahua"},
        {"label":"(GMT-07:00) Mountain Time (US & Canada)","value":"US/Mountain"},
        {"label":"(GMT-06:00) Central America","value":"America/Managua"},
        {"label":"(GMT-06:00) Central Time (US & Canada)","value":"US/Central"},
        {"label":"(GMT-06:00) Guadalajara, Mexico City, Monterrey","value":"America/Mexico_City"},
        {"label":"(GMT-06:00) Saskatchewan","value":"Canada/Saskatchewan"},
        {"label":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco","value":"America/Bogota"},
        {"label":"(GMT-05:00) Eastern Time (US & Canada)","value":"US/Eastern"},
        {"label":"(GMT-05:00) Indiana (East)","value":"US/East-Indiana"},
        {"label":"(GMT-04:00) Atlantic Time (Canada)","value":"Canada/Atlantic"},
        {"label":"(GMT-04:00) Caracas, La Paz","value":"America/Caracas"},
        {"label":"(GMT-04:00) Manaus","value":"America/Manaus"},
        {"label":"(GMT-04:00) Santiago","value":"America/Santiago"},
        {"label":"(GMT-03:30) Newfoundland","value":"Canada/Newfoundland"},
        {"label":"(GMT-03:00) Brasilia","value":"America/Sao_Paulo"},
        {"label":"(GMT-03:00) Buenos Aires, Georgetown","value":"America/Argentina/Buenos_Aires"},
        {"label":"(GMT-03:00) Greenland","value":"America/Godthab"},
        {"label":"(GMT-03:00) Montevideo","value":"America/Montevideo"},
        {"label":"(GMT-02:00) Mid-Atlantic","value":"America/Noronha"},
        {"label":"(GMT-01:00) Cape Verde Is.","value":"Atlantic/Cape_Verde"},
        {"label":"(GMT-01:00) Azores","value":"Atlantic/Azores"},
        {"label":"(GMT+00:00) Casablanca, Monrovia, Reykjavik","value":"Africa/Casablanca"},
        {"label":"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London","value":"Etc/Greenwich"},
        {"label":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","value":"Europe/Amsterdam"},
        {"label":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","value":"Europe/Belgrade"},
        {"label":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris","value":"Europe/Brussels"},
        {"label":"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb","value":"Europe/Sarajevo"},
        {"label":"(GMT+01:00) West Central Africa","value":"Africa/Lagos"},
        {"label":"(GMT+02:00) Amman","value":"Asia/Amman"},
        {"label":"(GMT+02:00) Athens, Bucharest, Istanbul","value":"Europe/Athens"},
        {"label":"(GMT+02:00) Beirut","value":"Asia/Beirut"},
        {"label":"(GMT+02:00) Cairo","value":"Africa/Cairo"},
        {"label":"(GMT+02:00) Harare, Pretoria","value":"Africa/Harare"},
        {"label":"(GMT+02:00) Jerusalem","value":"Asia/Jerusalem"},
        {"label":"(GMT+02:00) Minsk","value":"Europe/Minsk"},
        {"label":"(GMT+02:00) Windhoek","value":"Africa/Windhoek"},
        {"label":"(GMT+03:00) Kuwait, Riyadh, Baghdad","value":"Asia/Kuwait"},
        {"label":"(GMT+03:00) Moscow, St. Petersburg, Volgograd","value":"Europe/Moscow"},
        {"label":"(GMT+03:00) Nairobi","value":"Africa/Nairobi"},
        {"label":"(GMT+03:00) Tbilisi","value":"Asia/Tbilisi"},
        {"label":"(GMT+03:30) Tehran","value":"Asia/Tehran"},
        {"label":"(GMT+04:00) Abu Dhabi, Muscat","value":"Asia/Muscat"},
        {"label":"(GMT+04:00) Baku","value":"Asia/Baku"},
        {"label":"(GMT+04:00) Yerevan","value":"Asia/Yerevan"},
        {"label":"(GMT+04:30) Kabul","value":"Asia/Kabul"},
        {"label":"(GMT+05:00) Yekaterinburg","value":"Asia/Yekaterinburg"},
        {"label":"(GMT+05:00) Islamabad, Karachi, Tashkent","value":"Asia/Karachi"},
        {"label":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi","value":"Asia/Calcutta"},
        {"label":"(GMT+05:30) Sri Jayawardenapura","value":"Asia/Calcutta"},
        {"label":"(GMT+05:45) Kathmandu","value":"Asia/Katmandu"},
        {"label":"(GMT+06:00) Almaty, Novosibirsk","value":"Asia/Almaty"},
        {"label":"(GMT+06:00) Astana, Dhaka","value":"Asia/Dhaka"},
        {"label":"(GMT+06:30) Yangon (Rangoon)","value":"Asia/Rangoon"},
        {"label":"(GMT+07:00) Bangkok, Hanoi, Jakarta","value":"Asia/Bangkok"},
        {"label":"(GMT+07:00) Krasnoyarsk","value":"Asia/Krasnoyarsk"},
        {"label":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi","value":"Asia/Hong_Kong"},
        {"label":"(GMT+08:00) Kuala Lumpur, Singapore","value":"Asia/Kuala_Lumpur"},
        {"label":"(GMT+08:00) Irkutsk, Ulaan Bataar","value":"Asia/Irkutsk"},
        {"label":"(GMT+08:00) Perth","value":"Australia/Perth"},
        {"label":"(GMT+08:00) Taipei","value":"Asia/Taipei"},
        {"label":"(GMT+09:00) Osaka, Sapporo, Tokyo","value":"Asia/Tokyo"},
        {"label":"(GMT+09:00) Seoul","value":"Asia/Seoul"},
        {"label":"(GMT+09:00) Yakutsk","value":"Asia/Yakutsk"},
        {"label":"(GMT+09:30) Adelaide","value":"Australia/Adelaide"},
        {"label":"(GMT+09:30) Darwin","value":"Australia/Darwin"},
        {"label":"(GMT+10:00) Brisbane","value":"Australia/Brisbane"},
        {"label":"(GMT+10:00) Canberra, Melbourne, Sydney","value":"Australia/Canberra"},
        {"label":"(GMT+10:00) Hobart","value":"Australia/Hobart"},
        {"label":"(GMT+10:00) Guam, Port Moresby","value":"Pacific/Guam"},
        {"label":"(GMT+10:00) Vladivostok","value":"Asia/Vladivostok"},
        {"label":"(GMT+11:00) Magadan, Solomon Is., New Caledonia","value":"Asia/Magadan"},
        {"label":"(GMT+12:00) Auckland, Wellington","value":"Pacific/Auckland"},
        {"label":"(GMT+12:00) Fiji, Kamchatka, Marshall Is.","value":"Pacific/Fiji"},
        {"label":"(GMT+13:00) Nuku'alofa","value":"Pacific/Tongatapu"}
      ]
    }
  },
  created() {
    this.getEvent()
    this.getCalendar()
    this.getCategories()
  },
  computed: {
    isEdit () {
      return this.$route.params.id
    },
    isCopy () {
      return this.$route.params.copy_id !== '0'
    }
  },
  methods: {
    getEvent() {
      if(!this.isEdit && !this.isCopy) return
      axios.get('/event/info', {
        params: {id: this.$route.params.id || this.$route.params.copy_id}
      }).then((res) => {
        this.model = res.data.data;
        this.model.startType = startTypes[this.model.startType - 1]
        this.model.endType = endTypes[this.model.endType - 1]
        this.model.alarm1TimeType = alarmTimeTypes[this.model.alarm1TimeType - 1]
        this.model.alarm2TimeType = alarmTimeTypes[this.model.alarm2TimeType - 1]
        this.model.categoryType = categoryTypes[this.model.categoryType - 1]
        if(!this.isEdit) this.model.id = null
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    getCalendar () {
      axios.get('/calendar/info', {
        params: {id: parseInt(this.$route.params.cid)}
      }).then((res) => {
        this.calendar = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    getCategories() {
      axios.get('/category/list', {
        params: {calendar_id: parseInt(this.$route.params.cid)}
      }).then((res) => {
        this.categories = [{value: 0, label: this.$t('Без категории')}]
        if(res.data.data.length){
          res.data.data.forEach((val) => {
            this.categories.push({value: val.id, label: val.title});
          })
        }
        if(!this.isEdit) this.model.categoryType = this.categories.length > 1 ? categoryTypes[0] : categoryTypes[1]
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    save() {
      let data = JSON.parse(JSON.stringify(this.model));
      data.calendar_id = parseInt(this.$route.params.cid)
      data.startDays = parseInt(data.startDays || 0)
      data.startHours = parseInt(data.startHours || 0)
      data.startMinutes = parseInt(data.startMinutes || 0)
      data.startSeconds = parseInt(data.startSeconds || 0)
      data.endDays = parseInt(data.endDays || 0)
      data.endHours = parseInt(data.endHours || 0)
      data.endMinutes = parseInt(data.endMinutes || 0)
      data.endSeconds = parseInt(data.endSeconds || 0)
      data.alarm1Days = parseInt(data.alarm1Days || 0)
      data.alarm1Hours = parseInt(data.alarm1Hours || 0)
      data.alarm1Minutes = parseInt(data.alarm1Minutes || 0)
      data.alarm1Seconds = parseInt(data.alarm1Seconds || 0)
      data.alarm2Days = parseInt(data.alarm2Days || 0)
      data.alarm2Hours = parseInt(data.alarm2Hours || 0)
      data.alarm2Minutes = parseInt(data.alarm2Minutes || 0)
      data.alarm2Seconds = parseInt(data.alarm2Seconds || 0)
      data.startType = parseInt(Object.keys(startTypes).find(key => startTypes[key] === data.startType)) + 1;
      data.endType = parseInt(Object.keys(endTypes).find(key => endTypes[key] === data.endType)) + 1;
      data.alarm1TimeType = parseInt(Object.keys(alarmTimeTypes).find(key => alarmTimeTypes[key] === data.alarm1TimeType)) + 1;
      data.alarm2TimeType = parseInt(Object.keys(alarmTimeTypes).find(key => alarmTimeTypes[key] === data.alarm2TimeType)) + 1;
      data.categoryType = parseInt(Object.keys(categoryTypes).find(key => categoryTypes[key] === data.categoryType)) + 1;
      const url = this.isEdit ? '/event/edit' : '/event/add'
      axios.post(url, data, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.$router.push('/calendar/' + this.$route.params.cid)
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    },
    delete() {
      if(confirm(this.$t('Вы уверены, что хотите удалить событие ?'))){
        axios.post('/event/remove', {
          id: this.$route.params.id
        }, {
          headers: {'content-type': 'application/json'}
        }).then(() => {
          this.$router.push('/calendar/' + this.$route.params.cid)
        }).catch((error) => {
          this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
        })
      }
    }
  }
}
</script>
<style lang="scss">
input[type="datetime-local"], .input-group > input[type="time"]{
  width: 25%;
  flex: none;
}
form button {
  margin-right: 2em;
}
</style>
